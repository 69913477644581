$small: 576px;
$medium: 1024px;
a {
  text-decoration: none;
  color: #bdbdbd;
}

ol,
ul {
  list-style: none;
}

.button {
  width: 101px;
  height: 40px;
  border-width: 0;
  border-radius: 24px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  transition: 0.25s ease;

  &:focus {
    outline: 0;
  }

  &.joinUs {
    transition: opacity 0.2s;
    background: linear-gradient(180deg, #c53435 0%, #eb4d3d 100%);
    color: #ffffff;
  }
  &:hover {
    opacity: 0.85;
  }
  @media screen and(max-width:$medium) {
    height: 50px;
    margin: 10px 0 !important;
  }
}

.buttonLogin {
  width: 101px;
  height: 40px;
  border-width: 0;
  border-radius: 24px;
  font-weight: bold;
  font-size: 16px;
  background-color: white;
  color: #bdbdbd;
  transition: color 0.2s;
  transition: background-color 0.2s;
  cursor: pointer;
  &:hover {
    background-color: #e83424;
    color: white;

    &:hover {
      opacity: 85%;
    }
  }

  &.login {
    transition: background-color 0.2s, color 0.2s;
    background-color: white;
    color: #bdbdbd;

    &:hover {
      color: white;
      background-color: #e83424;
    }
  }
  @media screen and(max-width:$medium) {
    width: unset;
    height: 50px;
    margin: 10px 0;
  }
}

.tab {
  cursor: pointer;
  position: relative;
  padding-bottom: 0.4em;
  margin-right: 1em;
  font-weight: 500 !important;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  transition: color 0.1s;
  color: #bdbdbd;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';

    width: 100%;
    height: 3px;
  }

  &.active {
    color: #353332;
    &:after {
      color: #353332 !important;
      background-color: #e83424;
    }
  }
  &:hover {
    color: #e83424;
  }
  @media screen and(max-width:$medium) {
    margin: 0;
  }
}

.ml1 {
  margin-left: 1rem;
  @media screen and(max-width:$medium) {
    margin: 0;
  }
}

.brand {
  width: 93px;
  height: 68px;
  @media screen and(max-width:$small) {
    // height: 93px;
    padding-left: 25px;
  }
}

.buttonIcon {
  min-width: initial;
  padding: 0.5rem;
}

.siteHeader {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 999;
  padding: 0 16px;
  box-sizing: border-box;
  img {
    object-fit: cover;
  }
  @media screen and(max-width:$medium) {
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  @media screen and(max-width:$small) {
    width: 100vw;
    padding: 0;
  }
}

.siteHeader__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1152px;
  margin: 0 auto;
  @media screen and(max-width:$small) {
    margin: 0;
    display: none;
  }
}

.siteHeader__start {
  display: flex;
  @media (max-width: $medium) {
    margin: 0;
  }
}
.siteHeader__start__mobile {
  @media screen and(max-width:$small) {
    display: flex;
  }
}

.siteHeader__middle {
  @media (max-width: $medium) {
    display: none;
  }
}

.siteHeader__end {
  position: relative;
  @media (max-width: $medium) {
    display: none;
  }
}

.nav__wrapper {
  position: fixed;
  top: 56px;
  left: 0;
  width: Max(220px, 20%);
  bottom: 0;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  display: block;

  &.active {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }

  @media (max-width: $small - 1) {
    top: 104px;
    /* Use a CSS variable and get JS help */
  }
}

.nav__item {
  border-bottom: 1px solid rgba(#000, 0.2);

  a {
    display: block;
    padding: 1.5rem 1rem;
  }
}

.subNav {
  display: flex;
  flex-wrap: wrap;

  li:not(:last-child) {
    margin-right: 1.5rem;
  }
  span {
    color: #bdbdbd;
  }
  @media screen and(max-width:$medium) {
    display: unset;
    flex-wrap: unset;
    padding: 0;
    li:first-child {
      padding-top: 0 !important;
    }
    li:not(:last-child) {
      margin-right: 0;
      padding: 25px 0;
      text-align: center;
    }
  }
  @media (max-width: $small - 1) {
    // background: rgba(#000, 0.1);
    margin: 0 -1rem -1rem;
    padding: 0 1rem;
  }
}

.floatingNav {
  box-shadow: 0px 1px 10px #999;
  max-width: 100vw;
}
.bgMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 110vh;
  width: 100%;
  background-color: white;
  // overflow-y: scroll;
  @media screen and(min-width:$medium) {
    display: none;
  }
  @media screen and(min-width:$small) {
    width: 100vw;
  }
}
.siteHeader__endResponsive {
  display: flex;
  flex-direction: column;
  width: 20%;
  padding: 25px 0;
  @media screen and(max-width:$small) {
    width: unset;
    align-items: center;
    padding-bottom: 0;
  }
}
.siteHeader__middleResponsive {
  display: flex;
  flex-direction: column;
}
.closeIcon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
}
.openIcon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px 16px;
  @media screen and(min-width:$medium) {
    display: none;
  }
  @media screen and(max-width:$small) {
    // top: 15px;
  }
}
.line {
  border: 1px solid #f1f1f0;
  width: 50%;
  margin: 32px 25px;
}
.siteHeader__mobile {
  display: none;
  @media screen and(max-width:$small) {
    display: flex;
  }
}
.container {
  &.normal {
    img {
      border-radius: 50%;
      width: 80px;
      height: 80px;
      object-fit: cover;
    }
  }
  .avatar {
    width: 80px;
    height: 80px;
    background: #e83424;
    border-radius: 100%;
    font-size: 55px;
    font-weight: 600;
    color: white;
    display: grid;
    place-items: center;
  }
}
.basicInfo {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  .profilePath {
    margin-top: 32px;
    width: 120px;
    height: 120px;
    border-radius: 100px;
    background-color: rgba(220, 175, 165, 0.4);
  }
}
.userName {
  font-family: Kanit;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  padding-top: 24px;
  padding-bottom: 16px;
}
.userSetting {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;
  width: 166px;
  border: 1px solid #a8a8a8;
  color: #a8a8a8;
  box-sizing: border-box;
  border-radius: 100px;
  @media screen and(max-width:$small) {
    min-height: 58px;
  }
}
.coverItemMenu {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
