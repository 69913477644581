$small: 576px;
$medium: 1024px;
.wrap {
    max-width: 1152px;
    margin: 0 auto;
    display: flex;
    @media screen and(max-width:$medium) {
        padding: 0 2em;
    }
    @media screen and(max-width:$small) {
        flex-direction: column;
    }
}
.backGround {
    background: rgba(232, 52, 36, 0.85);
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    padding: 32px 0;
    color: white;
    z-index: 9;
    position: fixed;
    bottom: 0;
    &.accepted {
        display: none;
    }
}
.policy {
    color: black;
    text-decoration: underline;
}
.content {
    flex: 4;
}
.button {
    flex: 1;
    height: 40px;
    border-width: 0;
    border-radius: 24px;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    transition: 0.25s ease;
    padding: 8px 0;
    color: rgba(232, 52, 36, 1);
    @media screen and(max-width:$medium) {
        margin-top: 16px;
    }
    @media screen and(max-width:$small) {
    }
}
