.container {
  position: absolute;
  // display: flex;
  border-radius: 3px;
  z-index: 9;
  right: 0;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  display: none;
  &.show {
    display: flex;
  }
}

.profileContainer {
  display: flex;
  align-items: center;
  background: white;
  flex-direction: column;
  overflow: hidden;
  h4 {
    text-align: center;
    margin-top: 0.8em;
    margin-bottom: 0;
    font-size: 22px;
    font-weight: 500;
  }
}

.popup {
  // margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 286px;
  // height: 471px;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.list {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  width: 100%;
  a {
    padding: 16px 8px;
    font-weight: 300;
    align-self: center;
    line-height: 24px;
    font-size: 16px;
    cursor: pointer;
    // width: 100%;
  }
  li {
    padding: 16px 8px;
    font-weight: 300;
    align-self: center;
    line-height: 24px;
    font-size: 16px;
    cursor: pointer;
    // width: 100%;
  }
}

.wrapper {
  &.normal {
    img {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }
  .avatar {
    width: 40px;
    height: 40px;
    background: #e83424;
    border-radius: 100%;
    font-size: 30px;
    font-weight: 600;
    color: white;
    display: grid;
    place-items: center;
  }
}