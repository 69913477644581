.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.layoutAdmin {
  display: flex;
  flex-direction: row;
}

.is-hover {
  &:hover {
    cursor: pointer;
  }
}

.MuiPopover-paper {
  margin-left: 20px;
  margin-top: 20px;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 30px !important;
}

.MuiAccordionSummary-root.Mui-expanded.active {
  background: rgba(220, 175, 165, 0.4);
}

.MuiAccordion-root.Mui-expanded {
  margin: 0px !important;
}

.MuiAccordionSummary-expandIcon.Mui-expanded {
  color: red;
}

.MuiAccordionSummary-content.Mui-expanded {
  color: red;
}