$md: 1024px;
$sm: 576px;
.wrapper {
  max-width: 1152px;
  margin: 0 auto;
}
.footerBg {
  background: rgba(220, 175, 165, 0.4);
  padding: 48px 0 0 0;
  @media screen and(max-width:$md) {
    padding: 48px 16px 0 16px;
  }
  @media screen and(max-width:$sm) {
    margin-top: 30px;
  }
}
.cover {
  display: flex;
  flex-direction: column;
}
.aboutMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.about {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 45%;
  img {
    object-fit: contain;
  }
  p {
    font-family: Kanit;
    font-size: 14px;
    line-height: 22px;
    color: #353332;
  }
  @media screen and(max-width:$sm) {
    width: 100%;
  }
}
.link {
  display: flex;
  flex-direction: column;
  width: 50%;
  font-weight: 300;
  .menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    a {
      font-family: 'Kanit';
      font-size: 16px;
      color: #353332;
      width: 49%;
      text-decoration: none;
      transition: 0.5s;
      padding-top: 13px;
      &:hover {
        font-weight: bold;
        transform: font-weight;
      }
    }
  }
  p {
    font-family: 'Kanit';
    font-size: 14px;
    color: #da8b7c;
  }
  @media screen and(max-width:$sm) {
    width: 100%;
  }
}
.backToTop {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 10%;
  img {
    object-fit: contain;
    cursor: pointer;
    &:hover {
      transition: transform 0.5s;
      transform: scale(1.05);
    }
  }
  @media screen and(max-width:$sm) {
    width: 20%;
  }
}
.branch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and(max-width:$sm) {
    flex-direction: column;
  }
}
.branchCity {
  width: 50%;
  font-weight: 300;
  p {
    font-family: 'Kanit';
    font-size: 14px;
    color: #da8b7c;
  }
  @media screen and(max-width:$sm) {
    width: 100%;
  }
}
.icon {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 82%;
  img {
    object-fit: contain;
    padding: 0 8px 0 0;
  }
  div {
    font-family: 'Kanit';
    font-size: 16px;
    line-height: 24px;
    color: #939393;
  }
}
.copyRight {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 40px 0 0 0;
  p {
    width: 80%;
    font-family: 'Kanit';
    font-size: 14px;
    color: #da8b7c;
  }
  a {
    // width: 15%;
    font-family: 'Kanit';
    font-size: 14px;
    color: #da8b7c;
  }
  @media screen and(max-width:$md) {
    p {
      width: 60%;
    }
    a {
      // width: 20%;
    }
  }
  @media screen and(max-width:$sm) {
    flex-direction: column;
    width: 100%;
    p {
      width: 100%;
      text-align: center;
    }
    a {
      // width: 100%;
    }
  }
}

.aboutLink {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and(max-width:$sm) {
    flex-direction: column;
    width: 100%;
  }
}
.coverPolicy {
  width: 20%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  @media screen and(max-width:$sm) {
    width: 90%;
    justify-content: space-around;
  }
}
.contact {
  display: flex;
  width: 40%;
  flex-direction: column;
  p{
    font-family: 'Kanit';
    font-size: 14px;
    color: #da8b7c;
  }
  .item {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    line-height: 20px;
    margin: 8px;
    a{
      color: #939393;
    }
    img {
      width: 25px;
      height: 25px;
    }
  }
  @media screen and(max-width:$sm) {
    width: 100%;
  }
}
