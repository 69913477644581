.container {
  cursor: pointer;
  &.small {
    img {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }
  &.normal {
    img {
      border-radius: 50%;
      width: 80px;
      height: 80px;
    }
  }
  .avatar {
    width: 40px;
    height: 40px;
    background: #e83424;
    border-radius: 100%;
    font-size: 20px;
    font-weight: 600;
    color: white;
    display: grid;
    place-items: center;
  }
}
